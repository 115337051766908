const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default {
    SERVER_HOST: IS_DEV ? 'http://localhost:9090' : "https://testapi.slws.co.ke",
    WS_HOST: IS_DEV ? 'ws://localhost:9090' : "wss://testapi.slws.co.ke",
    API_URL: '/api/v1/',
    FILE_URL: '/files/',
    WS: 'ws',
    LOGIN: 'user/login',
    REGISTER: 'user/register',
    SEND_RESET_CODE: 'user/send-reset-code',
    RESET_PASSWORD: 'user/reset-password',
    UPDATE_PROFILE: 'user/update/profile',
    UPDATE_PHOTO: 'user/update/photo',
    UPDATE_PASSWORD: 'user/update/password',
    PLACE_ORDER: 'order/new',
    PAPER_LIST: 'paper/list-front',
    SUBJECT_LIST: 'subject/list-front',
    FIND_RATE: 'rate/find-service',
    TOPUP: 'topup',
    ORDER_NEW: 'order/new',
    GET_BALANCE: 'user/find-balance',
    GET_STATS: 'user/find-stats',
    ORDER_LIST: 'order/list-user',
    FIND_ORDER: 'order/find-user',
    CANCEL_ORDER: 'order/cancel-user',
    RATE_ORDER: 'order/rate',
    SEND_MESSAGE: 'message/send-user',
    LIST_MESSAGES: 'message/get-order-user',
    LIST_USER_MESSAGES: 'message/get-user',
    LIST_USER_MESSAGES_COUNT: 'message/get-user-count',
    TRANSACTION_LIST: 'transaction/list-user',
};

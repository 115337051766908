import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";

const serviceTypes = [
    {
        label: "Writing from scratch",
        value: "scratch"
    },
    {
        label: "Rewriting",
        value: "rewriting"
    },
    {
        label: "Editing",
        value: "editing"
    }]
const educationLevels = [
    {
        label: "High School",
        value: "high-school"
    },
    {
        label: "University",
        value: "university"
    }]
const citationFormats = [
    {
        label: "MLA",
        value: "mla"
    },
    {
        label: "APA",
        value: "apa"
    },
    {
        label: "Chicago/Turabian",
        value: "Chicago/Turabian"
    },
    {
        label: "Havard",
        value: "Havard"
    },
    {
        label: "Not Applicable",
        value: "Not Applicable"
    },
    {
        label: "Other",
        value: "Other"
    }]


const NewOrder = props => {

    const history = useHistory();

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [serviceAvailable, setServiceAvailable] = useState(false);

    const [topic, setTopic] = useState('');
    const [noOfPages, setNoOfPages] = useState(1);
    const [serviceType, setServiceType] = useState('');
    const [citedResources, setCitedResources] = useState(0);
    const [citationFormat, setCitationFormat] = useState(0);
    const [instructions, setInstructions] = useState('');
    const [promoCode, setPromoCode] = useState('');
    const [instructionFile, setInstructionFile] = useState('');
    const [currentPPA, setCurrentPPA] = useState(0);
    const [levelEducation, setLevelEducation] = useState(educationLevels[1]);
    const [deadlineHours, setDeadlineHours] = useState(6);
    const [deadlineDays, setDeadlineDays] = useState(0);
    const [deadlineMonths, setDeadlineMonths] = useState(0);
    const [hours, setHours] = useState(0);

    const [currentRate, setCurrentRate] = useState({
        less_24: 20,
        less_72: 15,
        more_72: 10
    });

    const [orderTotal, setOrderTotal] = useState(0);
    console.log(props.location.state)


    const [paper, setPaper] = useState(props.location.state ? props.location.state.paper || '' : '');
    const [subject, setSubject] = useState(props.location.state ? props.location.state.subject || '' : '');


    useEffect(() => {
        document.title = "Home | " + Strings.APP_DESCRIPTION;
        findRate()

        updateDeadline()
        if (globalState.userIsIn) {
            placeOrder()
            globalState.setUserIsIn(false)
        }
    }, [globalState]);

    const findRate = (service = serviceType, education = levelEducation) => {
        if (!service) {
            return
        }

        let data = new FormData();
        data.append("service", service.value)
        data.append("education", education.value)

        setIsLoading(true);

        postData(Endpoints.FIND_RATE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    if (res.data.ID === 0) {
                        setServiceAvailable(false)
                        Notify("Service not available", ERROR)
                        return
                    }
                    setServiceAvailable(true)
                    setCurrentRate(res.data)
                    updateDeadline(deadlineMonths, deadlineDays, deadlineHours, res.data)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const updatePrice = (timeRate = currentPPA, pages = noOfPages, rate = currentRate) => {
        setOrderTotal(pages * rate.more_72)

        if (timeRate === 24) {
            setOrderTotal(pages * rate.less_24)
        } else if (timeRate === 72) {
            setOrderTotal(pages * rate.less_72)
        }
    }

    const updateDeadline = (months = deadlineMonths, days = deadlineDays, hours = deadlineHours, rate = currentRate) => {
        let totalHours = hours, timeRate = 100

        if (days > 0) {
            totalHours = totalHours + (days * 24)
        }

        if (months > 0) {
            totalHours = totalHours + (months * 28 * 24)
        }

        if (totalHours <= 24) {
            timeRate = 24
        }

        if (totalHours > 24 && totalHours <= 72) {
            timeRate = 72
        }

        setCurrentPPA(timeRate)

        setHours(totalHours)
        updatePrice(timeRate, noOfPages, rate)

    }

    const placeOrder = () => {
        if (!(!!paper)) {
            Notify("Paper is required", ERROR)
            return
        }

        if (!(!!subject)) {
            Notify("Subject is required", ERROR)
            return
        }

        if (!(!!levelEducation)) {
            Notify("Education level is required", ERROR)
            return
        }

        if (!(!!citationFormat)) {
            Notify("Citation format is required", ERROR)
            return
        }

        if (!(!!topic)) {
            Notify("Topic is required", ERROR)
            return
        }

        let data = new FormData();
        data.append("topic", topic)
        data.append("subject", subject.value)
        data.append("paper", paper.value)
        data.append("price", orderTotal);
        data.append("level_of_education", levelEducation.value);
        data.append("deadline_hours", hours);
        data.append("cited_resources", citedResources);
        data.append("service", serviceType.value);
        data.append("citation_format", citationFormat.value);
        data.append("paper_instructions", instructions);
        data.append("promo_code", promoCode);
        data.append("no_of_pages", noOfPages);

        for (let i = 0; i < instructionFile.length; i++) {
            data.append(`instruction_files[${i}]`, instructionFile[i])
        }

        setIsLoading(true);

        postData(Endpoints.ORDER_NEW, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    history.push(res.data)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    return (
        <section className="create-job">
            <div className="container">
                <div className="box">
                    <div className="box-header">
                        <h4>Enter order details</h4>
                    </div>
                    <div className="box-body">

                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Type of paper</label>
                                            <SelectView
                                                options={globalState.papers}
                                                placeholder={"Select a paper"}
                                                onChange={e => setPaper(e)}
                                                value={paper}/>
                                        </div>
                                    </div>


                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <SelectView
                                                options={globalState.subjects}
                                                placeholder={"Select a subject"}
                                                onChange={e => setSubject(e)}
                                                value={subject}/>
                                        </div>
                                    </div>


                                    <div className="col-sm-12 form-holder" style={{marginTop: 15}}>
                                        <label>Topic</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Topic"
                                            required
                                            onChange={e => setTopic(e.target.value)}
                                            value={topic}
                                        />
                                    </div>

                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <label>Number of pages ( 1 page = 300 words )</label>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <button id="btnMinusPages" className="btn btn-success" onClick={e => {
                                                    if (noOfPages > 1) {
                                                        setNoOfPages(noOfPages - 1)
                                                        updatePrice(currentPPA, noOfPages - 1)
                                                    }
                                                }}><i
                                                    className="fa fa-minus"></i></button>
                                            </div>
                                            <div className="col-sm-8">
                                                <input type="text"
                                                       className="form-control full-width text-center"
                                                       placeholder="Number of pages"
                                                       id="orderPages"
                                                       disabled
                                                       name="no_of_pages"
                                                       onChange={e => setNoOfPages(e.target.value)}
                                                       value={noOfPages}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <button className="btn btn-success" onClick={e => {
                                                    setNoOfPages(noOfPages + 1)
                                                    updatePrice(currentPPA, noOfPages + 1)
                                                }}><i
                                                    className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <label>Type of service</label>
                                        <SelectView
                                            options={serviceTypes}
                                            placeholder={"Select a service"}
                                            onChange={e => {
                                                setServiceType(e)
                                                findRate(e, levelEducation)
                                            }}
                                            value={serviceType}/>
                                    </div>


                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="row">

                                    <div className="col-sm-12">
                                        <label>Level of education</label>
                                        <SelectView
                                            options={educationLevels}
                                            placeholder={"Select a level"}
                                            onChange={e => {
                                                setLevelEducation(e)
                                                findRate(serviceType, e)
                                            }}
                                            value={levelEducation}/>
                                    </div>

                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <label>Number of cited resources</label>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <button id="btnMinusCite" className="btn btn-success" onClick={e => {
                                                    if (citedResources > 0) {
                                                        setCitedResources(citedResources - 1)
                                                    }
                                                }}><i
                                                    className="fa fa-minus"></i></button>
                                            </div>
                                            <div className="col-sm-8">
                                                <input name="number_of_cited_resources" type="text"
                                                       className="form-control full-width text-center"
                                                       placeholder="Number of cited resources"
                                                       id="orderCitedResources" disabled
                                                       onChange={e => setCitedResources(e.target.value)}
                                                       value={citedResources}/>
                                            </div>
                                            <div className="col-sm-2">
                                                <button id="btnAddCite" className="btn btn-success" onClick={e => {
                                                    setCitedResources(citedResources + 1)
                                                }}><i
                                                    className="fa fa-plus"></i>
                                                </button>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <label>Format of citation</label>

                                        <SelectView
                                            options={citationFormats}
                                            placeholder={"Select a format"}
                                            onChange={e => setCitationFormat(e)}
                                            value={citationFormat}/>
                                    </div>

                                    <div className="col-sm-12" style={{marginTop: 15}}>
                                        <label id="timezoneLabel">Deadline from submission</label>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <small style={{fontStyle: 'italic'}}>Date - You will charged
                                                    USD {currentRate.less_24} ppa
                                                    for deadlines less than 24 hours, USD {currentRate.less_72} for
                                                    deadlines less than
                                                    72
                                                    hours and USD {currentRate.more_72} for greater than 72 hours.
                                                </small>

                                                <input type="text"
                                                       className="form-control full-width"
                                                       id="rateLess24"
                                                       value={currentRate.less_24}
                                                       style={{display: "none"}}
                                                />
                                                <input type="text"
                                                       className="form-control full-width"
                                                       id="rateLess72"
                                                       value={currentRate.less_72}
                                                       style={{display: "none"}}
                                                />
                                                <input type="text"
                                                       className="form-control full-width"
                                                       id="rateMore72"
                                                       value={currentRate.more_72}
                                                       style={{display: "none"}}
                                                />

                                                <input type="text"
                                                       className="form-control full-width"
                                                       style={{display: "none"}}
                                                       onChange={e => {
                                                           setCurrentPPA(e.target.value)
                                                           updatePrice()
                                                       }}
                                                       onInput={e => {
                                                           setCurrentPPA(e.target.value)
                                                           updatePrice()
                                                       }}
                                                />


                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <small style={{fontWeight: 500}}>Month(s)
                                                </small>

                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min={0}
                                                    required
                                                    onChange={e => {
                                                        setDeadlineMonths(e.target.value)
                                                        updateDeadline(e.target.value, deadlineDays, deadlineHours)
                                                    }}
                                                    value={deadlineMonths}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <small style={{fontWeight: 500}}>Day(s)
                                                </small>

                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min={0}
                                                    required
                                                    onChange={e => {
                                                        setDeadlineDays(e.target.value)
                                                        updateDeadline(deadlineMonths, e.target.value, deadlineHours)
                                                    }}
                                                    value={deadlineDays}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <small style={{fontWeight: 500}}>Hour(s)
                                                </small>

                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    required
                                                    onChange={e => {
                                                        setDeadlineHours(e.target.value)
                                                        updateDeadline(deadlineMonths, deadlineDays, e.target.value)
                                                    }}
                                                    value={deadlineHours}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Paper Instructions - Write your instructions below or upload a
                                            file </label>
                                        <textarea name="paper_instructions" type="text" className="form-control"
                                                  id="orderInstructions"
                                                  placeholder="Paper instructions"
                                                  style={{maxWidth: '100%', maxHeight: 300, minWidth: '100%'}}
                                                  onChange={e => setInstructions(e.target.value)}
                                                  value={instructions}
                                        />
                                        <label>Or</label><br/>
                                        <small>Total maximum files' size is 100 mb</small>
                                        <input type="file"
                                               multiple
                                               className="form-control full-width"
                                               id="orderFile"
                                               name="instruction_files"
                                               onChange={e => setInstructionFile(e.target.files)}
                                        />
                                    </div>

                                    <div className="col-sm-12 form-holder" style={{marginTop: 15}}>
                                        <label>Promo Code</label>
                                        <input type="text"
                                               className="form-control"
                                               placeholder="Promo Code" onChange={e => setPromoCode(e.target.value)}
                                               value={promoCode}
                                        />
                                        <small>*Promo codes are processed and discounts applied when the order
                                            is placed.
                                        </small>
                                    </div>

                                    <div className="text-center col-sm-12" style={{marginTop: 40}}>

                                        {isLoading &&
                                        <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/>
                                        }

                                        {!isLoading &&
                                        <div>
                                            <label style={{fontWeight: 700}}>Order Total</label><br/>
                                            <span
                                                style={{fontSize: 35, fontWeight: 600}}
                                                className="text-success">USD <span
                                                id="orderPrice">{orderTotal}.00</span></span>
                                            <br/>

                                            {globalState.userLoggedIn &&
                                            <button type="submit" className="btn btn-m btn-success"
                                                    onClick={placeOrder}
                                                    style={{
                                                        fontWeight: 500, marginTop: 10
                                                    }}>PLACE
                                                ORDER
                                            </button>
                                            }

                                            {!globalState.userLoggedIn &&
                                            <a href="javascript:void(0)" data-toggle="modal"
                                               data-target="#signinOrder" className="btn btn-m btn-success"
                                               style={{
                                                   fontWeight: 500, marginTop: 10
                                               }}>PLACE
                                                ORDER
                                            </a>
                                            }
                                            <br/>
                                            <small>*These funds will be temporarily held and allocated to this
                                                order. You hold
                                                the rights to release the funds after order completion. In case
                                                of order
                                                cancellation, you will be refunded.
                                            </small>

                                        </div>
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )
};

export default NewOrder;

import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {clearItem, retrieveItem} from "../../Utils/Storage";
import {useGlobal} from "../../Store";
import Login from "../Login";
import {connect, sendMsg} from "../../Utils/WSocket";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import Popup from 'react-popup';
import {goToPage} from "../../Utils/Common";
import Home from "../Home";
import NewOrder from "../Orders/NewOrder";
import TopUp from "../Orders/TopUp";
import OrderList from "../Orders/List";
import OrderDetail from "../Orders/Details";
import MessageList from "../Messages/List";
import TransactionList from "../Transactions/List";
import Profile from "../Profile/Profile";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import ForgotPassword from "../Profile/ForgotPassword";
import ResetPassword from "../Profile/ResetPassword";


const Body = props => {
    let history = useHistory();
    const [globalState, globalActions] = useGlobal();

    function loadNotCount() {
        let data = new FormData();
        postData(Endpoints.LIST_USER_MESSAGES_COUNT, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    globalActions.setNotCount(res.data)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const checkUser = () => {
        let user = retrieveItem('user');
        console.log(user)
        if (user != null) {
            user = JSON.parse(user);
            let userLoggedIn = true;
            globalActions.setUserLoggedIn(userLoggedIn);
            globalActions.setCurrentUser(user);

            connectToSocket("client_" + user.ID)
            setTimeout(() => sendMsg("Hello"), 5000)
        }
        globalActions.setUserCheckComplete(true)
    };

    const onCon = () => {
        Notify('Connected', SUCCESS)

        setInterval(() => {
            sendMsg("alive")
        }, 35000)
    }

    const onError = e => {
        Notify('Connection lost', ERROR)
    }

    const onClose = e => {
        Notify('Connection lost', ERROR)
    }

    const connectToSocket = id => {
        connect(id, onCon, onData, onClose, onError)
    }

    const onData = e => {
        let data = JSON.parse(e.data)
        Notify(data.body, SUCCESS, true)
        loadNotCount()

        switch (data.data_type) {
            case "new_request":
                Popup.create({
                    title: 'New order received',
                    content: data.body,
                    buttons: {
                        left: [{
                            text: 'View orders',
                            className: 'success',
                            action: function () {
                                Popup.close();
                                goToPage(history, "/requests/incoming")
                            }
                        }],
                        right: [{
                            text: 'Cancel',
                            className: '',
                            action: function () {
                                Popup.close();
                            }
                        }]
                    }
                });
                break
        }
    }


    useEffect(() => {
        checkUser()
        //connectToSocket()

        //setTimeout(() => sendMsg("Hello"), 5000)
    }, []);

    return (
        <main>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/order' component={NewOrder}/>
                <Route exact path='/topup/:id/:amount' render={props => {
                    const {
                        match: {
                            params: {id, amount}
                        }
                    } = props;

                    return (
                        <TopUp
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}&amount=${amount}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/messages' component={MessageList}/>
                <Route exact path='/transactions' component={TransactionList}/>
                <Route exact path='/orders' component={OrderList}/>
                <Route exact path='/order-details/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <OrderDetail
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
                <Route exact path='/profile' component={Profile}/>
                <Route exact path='/forgot-password' component={ForgotPassword}/>
                <Route exact path='/reset-password/:id' render={props => {
                    const {
                        match: {
                            params: {id}
                        }
                    } = props;

                    return (
                        <ResetPassword
                            title={`Details for ${id} : ${id}`}
                            key={`id=${id}`}
                            {...props}
                        />
                    );
                }}/>
            </Switch>
        </main>
    )

};

export default Body;

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import Colors from "../../Constants/Colors";
import avatar from "../../Assets/Images/a3.png";
import SideBarNav from "./SideBarNav";
import AuthProvider from "../Views/AuthProvider";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import logo from '../../Assets/Images/logo.jpg';
import userPhoto from '../../Assets/Images/a3.png';
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";

const Header = props => {

    const [globalState, globalActions] = useGlobal();

    const [userBalance, setUserBalance] = useState({actual_balance:0})

    let location = useLocation();
    const checkIsIndex = () => {
        return location.pathname === "/"
    }

    const [isIndex, setIsIndex] = useState(checkIsIndex());


    useEffect(() => {
        loadPapers()
        loadSubjects()
        loadBalance()
        loadNotCount()

        setInterval(() => {
            loadNotCount()
        }, 10000)
        setIsIndex(checkIsIndex())

    }, [globalState.userLoggedIn, location.pathname])

    function loadSubjects() {
        let data = new FormData();
        postData(Endpoints.SUBJECT_LIST, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    let optionList = [];
                    (res.data || []).map((i, k) => {
                        optionList.push({...i, label: i.title, value: i.title})
                    })
                    globalActions.setSubjects(optionList);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function loadBalance() {
        let data = new FormData();
        postData(Endpoints.GET_BALANCE, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setUserBalance(res.data)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function loadPapers() {
        let data = new FormData();

        postData(Endpoints.PAPER_LIST, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    let optionList = [];
                    (res.data || []).map((i, k) => {
                        optionList.push({...i, label: i.title, value: i.title})
                    })
                    globalActions.setPapers(optionList);
                }

            })
            .catch((error) => {
                console.log(error)
            });
    }

    function loadNotCount() {
        let data = new FormData();
        postData(Endpoints.LIST_USER_MESSAGES_COUNT, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    globalActions.setNotCount(res.data)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <nav
            className={isIndex ? "navbar navbar-default navbar-mobile navbar-fixed white no-background bootsnav" : "navbar navbar-default navbar-mobile navbar-fixed light bootsnav"}>
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse"
                            data-target="#navbar-menu">
                        <i className="fa fa-bars"></i>
                    </button>
                    <a className="navbar-brand" href="/" style={{marginTop: 4}}>
                        <img src={logo} className="logo logo-display" alt="SLWS"
                             style={{height: 40, marginBottom: 50}}/>
                        <img src={logo} className="logo logo-scrolled"
                             alt="SLWS" style={{height: 40, marginBottom: 50}}/>
                    </a>

                </div>

                <div className="collapse navbar-collapse" id="navbar-menu">

                    {!globalState.userLoggedIn &&
                    <ul className="nav navbar-nav navbar-left" data-in="fadeInDown" data-out="fadeOutUp">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                    </ul>
                    }

                    {globalState.userLoggedIn &&
                    <ul className="nav navbar-nav navbar-left" data-in="fadeInDown" data-out="fadeOutUp">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/messages">Messages <span
                            style={{fontWeight: 800, color: Colors.red_700}}>({globalState.notCount})</span></Link></li>
                        <li><Link to="/orders">Orders</Link></li>
                        <li><Link to="/transactions">Transactions</Link></li>
                        <li><Link to="/topup/0/0">Top Up</Link></li>
                    </ul>
                    }


                    <ul className="nav navbar-nav navbar-right">

                        {globalState.userLoggedIn &&
                        <li className="br-right dropdown sign-up">
                            <a className="dropdown-toggle" data-toggle="dropdown" href="/profile">
                                <img
                                    src={globalState.currentUser.photo !== "" ? Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo : userPhoto}
                                    className="img-responsive img-circle" alt=""
                                    style={{
                                        width: 30,
                                        height: 30,
                                        alignSelf: 'center'
                                    }}/>
                                <span id="currentUserName">{globalState.currentUser.name} (
                                    <span style={{fontWeight: 800, color: Colors.green_700}}>
                                        (${userBalance.actual_balance})
                            </span>)
                            </span>
                            </a>
                        </li>
                        }

                        {!globalState.userLoggedIn &&
                        <li className="br-right"><a href="javascript:void(0)" data-toggle="modal"
                                                    data-target="#signin"
                                                    id="loginBtn"><i
                            className="login-icon ti-user"></i>Login</a></li>
                        }


                        <li className="sign-up"><Link className="btn-signup red-btn" to="/order"
                                                      id="placeOrderBtn"
                                                      style={{fontWeight: 700}}>Place
                            Order</Link></li>
                    </ul>

                </div>
            </div>
        </nav>
    )
};

export default Header;

import Endpoints from "../Constants/Endpoints";
import {storeItem} from "./Storage";

export const handleUserAccess = (res, globalActions, redToAccount, callback) => {
    let user = res.data.user;
    let token = res.data.token;

    console.log(user)

    storeItem('user', JSON.stringify(user));
    storeItem('token', token);
    globalActions.setCurrentUser({});
    globalActions.setCurrentUser(user);
    globalActions.setUserLoggedIn(true);

    if (redToAccount) {
        window.location.assign(process.env.PUBLIC_URL + "/profile")
    }else{
        //window.location.reload()
        callback();
    }
};

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import BackgroundOne from '../../Assets/Images/bg.jpg';
import logo from '../../Assets/Images/logo.jpg';
import Book from '../../Assets/Images/book.jpg';
import Essay from '../../Assets/Images/essay.jpg';
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import Strings from "../../Constants/Strings";
import {ERROR, Notify} from "../../Utils/Notify";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";

const Home = props => {

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [paper, setPaper] = useState(0);
    const [subject, setSubject] = useState(0);

    const proceedToOrder = () => {
        if (paper.value === 0) {
            Notify("Select a paper", ERROR)
            return
        }

        if (subject.value === 0) {
            Notify("Select a subject", ERROR)
            return
        }

        history.push("/order", {paper: paper, subject: subject})
    }


    useEffect(() => {
        document.title = "Home | " + Strings.APP_DESCRIPTION;
    }, [globalState.subjects, globalState.papers]);


    return (
        <div>
            <div className="main-banner" style={{
                backgroundImage: `url(${BackgroundOne})`,
            }}>
                <div className="container">
                    <div className="col-md-10 col-sm-10 col-md-offset-1 col-sm-offset-1">

                        <div className="caption text-center cl-white">
                            <h2>Need an essay? We got you.</h2>
                            <p>Submit your essay requirements and get your job done by our team of high quality
                                professional
                                writers at the best price.</p>
                        </div>

                        <fieldset className="home-form-1 icon-field">

                            <div className="col-md-5 col-sm-5">
                                <div className="form-group" style={{marginLeft:5}}>
                                    <div className="icon-addon addon-lg">
                                        <SelectView
                                            options={globalState.papers}
                                            placeholder={"Select a paper"}
                                            onChange={e => setPaper(e)}
                                            value={paper}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5 col-sm-5">
                                <div className="form-group" style={{marginRight:5}}>
                                    <div className="icon-addon addon-lg">
                                        <SelectView
                                            options={globalState.subjects}
                                            placeholder={"Select a subject"}
                                            onChange={e => setSubject(e)}
                                            value={subject}/>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-2 col-sm-2 padd-0">
                                <button type="submit" className="btn theme-btn cl-white seub-btn"
                                        onClick={proceedToOrder}>Continue
                                </button>
                            </div>

                        </fieldset>

                        <div className="text-center">
                            <div className="choose-opt">
                                <div className="choose-opt-box"><span>OR</span></div>
                            </div>
                            <a href="javascript:void(0)" data-toggle="modal" data-target="#shareDialog"
                               className="btn btn-hiring btn-m mrg-5"><span
                                className="ti-share-alt padd-r-5"></span>Refer and get 10% Off</a>
                        </div>


                    </div>
                    `
                </div>
            </div>

            <section className="how-it-works">
                <div className="container">

                    <div className="row" data-aos="fade-up">
                        <div className="col-md-12">
                            <div className="heading">
                                <h2>How It Works?</h2>
                                <p>Every day, hundreds of people stream to Silverline Writing Service to get their job
                                    done. Join
                                    the community
                                    and enjoy the best services from us.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-4 col-sm-4">
                            <div className="work-process">
							<span className="process-icon bg-danger-light">
								<i className="ti-user"></i>
								<span className="process-count bg-danger cl-white">1</span>
							</span>
                                <h4>Place An Order</h4>
                                <p>Post your assignment or essay requirements on our website.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="work-process step-2">
							<span className="process-icon bg-success-light">
								<i className="ti-pencil-alt"></i>
								<span className="process-count bg-success cl-white">2</span>
							</span>
                                <h4>We Deliver</h4>
                                <p>Let our team of high quality experienced writers handle your job within your
                                    timeline.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="work-process step-3">
							<span className="process-icon bg-purple-light">
								<i className="ti-thumb-up"></i>
								<span className="process-count bg-purple cl-white">3</span>
							</span>
                                <h4>You Pay</h4>
                                <p>Once satisfied with the job, pay with ease using PayPal or your wallet.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="padd-0">
                <div className="container-fluid padd-0">

                    <div className="col-md-12 col-sm-12 padd-0">
                        <div className="half-box candidate-box text-center"
                             style={{
                                 backgroundImage: `url(${Essay})`,
                             }}>
                            <h2>Need an essay?</h2>
                            <p>Place your order now and get your job done.</p>
                            <a href="/order" className="btn theme-btn btn-radius btn-m"
                               style={{width: 'auto', textTransform: 'none'}}><strong>Place Order</strong></a>
                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="container">

                    <div className="row" data-aos="fade-up">
                        <div className="col-md-12">
                            <div className="heading">
                                <h2>Why choose us?</h2>
                                <p>Be part of the amazing clients that love what we do. Here's what you get from every
                                    job
                                    posted</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-ruler-pencil" aria-hidden="true"></i>
                                <h4>Plagiarism Free</h4>
                                <p>We guarantee a delivery that does not contain any plagiarised content. We do our
                                    research from
                                    various sources to offer the best.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-desktop" aria-hidden="true"></i>
                                <h4>Fast Communication</h4>
                                <p>With time being a factor, we reply to messages and orders as fast as possible for the
                                    best
                                    experience.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-star" aria-hidden="true"></i>
                                <h4>Unique Content</h4>
                                <p>With a detailed research process, we guarantee that your delivery will always be
                                    unique to any
                                    previously done work.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-stats-up" aria-hidden="true"></i>
                                <h4>Fast Delivery</h4>
                                <p>Our swift response to orders allows us to deliver your order as fast as possible
                                    within your
                                    time-line
                                    with quality in focus.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-headphone-alt" aria-hidden="true"></i>
                                <h4>24/7 Support</h4>
                                <p>Chat with us at any time of the day, and let's help you get around the site without
                                    any trouble.
                                    We're always there for you.</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                            <div className="features-box">
                                <i className="theme-cl ti-bar-chart-alt" aria-hidden="true"></i>
                                <h4>Best Experience</h4>
                                <p>We are a team of highly experience writers and with this, you are guaranteed the best
                                    service
                                    from us.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section className="tag-sec" style={{
                backgroundImage: `url(${Book})`,
            }}>
                <div className="container">
                    <div className="col-md-10 col-md-offset-1">
                        <div className="tag-content">
                            <img src={logo} className="logo logo-display" alt=""
                                 style={{height: 50, marginBottom: 50}}/><br/>
                            <h2>Will you do my assignment?</h2>
                            <p>Of course, We understand that sometimes it can be difficult to do that extra research,
                                write that
                                business plan, get that speech right, or sometimes
                                you're too busy to get that assignment done. Just
                                post your requirements here and we got you covered anytime.</p>
                            <a href="/order" className="btn theme-btn btn-radius"
                               style={{width: 'auto', textTransform: 'none'}}><strong>
                                Place an order now<i
                                className="ti-shift-right"></i></strong></a>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
};

export default Home;

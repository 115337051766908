import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import MessageListSection from "./MessageListSection";


const MessageList = props => {

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        document.title = "Messages | " + Strings.APP_DESCRIPTION;

    }, []);


    return (
        <AuthProvider>
            <section className="create-company">
                <div className="container">

                    <div className="detail-wrapper">

                        <div className="detail-wrapper-header">
                            <h4>My Messages</h4>
                        </div>
                        <div className="detail-wrapper-body">

                            <div className="tab style-1" role="tabpanel">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className="active">
                                        <a href="#current" aria-controls="home" role="tab" data-toggle="tab">Unread</a>
                                    </li>
                                    <li role="presentation">
                                        <a href="#completed" aria-controls="profile" role="tab"
                                           data-toggle="tab">All</a>
                                    </li>
                                </ul>
                                <div className="tab-content tabs">

                                    <div role="tabpanel" className="tab-pane fade in active" id="current">
                                        <MessageListSection status={0}/>
                                    </div>

                                    <div role="tabpanel" className="tab-pane fade" id="completed">
                                        <MessageListSection status={-1}/>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
            </section>
        </AuthProvider>

    )
};

export default MessageList;

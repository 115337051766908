import React, {useEffect, useState} from 'react';
import Header from "./Components/Layout/Header";
import {ToastHolder} from "./Utils/Notify";
import ScrollToTop from "./Components/Layout/ScrollToTop";
import Colors from "./Constants/Colors";
import Body from "./Components/Layout/Body";
import {useLocation} from "react-router-dom";
import Popup from 'react-popup';
import Footer from "./Components/Layout/Footer";
import LoginOrder from "./Components/LoginOrder";
import Login from "./Components/Login";

function App() {
    let location = useLocation();
    const [isIndex, setIsIndex] = useState(location.pathname === "/" || location.pathname === "/register");

    return (
        <body>
        <Popup className="mm-popup"
               btnClass="mm-popup__btn"
               closeBtn={true}
               closeHtml={null}/>
        <Header/>
        <Body/>
        <Footer/>
        <Login/>
        <LoginOrder/>
        <ToastHolder/>
        <ScrollToTop/>
        </body>
    );
}

export default App;

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";

const ipnUrl = ""
const cancelUrl = ""
const successUrl = ""
const paypalUrl = ""


const TopUp = props => {

    const {amount, id} = props.match.params
    const history = useHistory();
    const [topupItem, setTopupItem] = useState({
        pay_pal_id: ''
    })
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Top Up | " + Strings.APP_DESCRIPTION;

        findSettings()

    }, []);

    const findSettings = () => {
        setIsLoading(true);

        let data = new FormData();
        data.append("order_id", id)

        postData(Endpoints.TOPUP, data)
            .then(res => res.json())
            .then(res => {

                setIsLoading(false);
                if (res.success === 1) {
                    setTopupItem(res.data)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }


    return (
        <AuthProvider>
            <section className="create-job">
                <div className="container">

                    <div className="row">

                        <div className="col-sm-4">


                            <div className="detail-wrapper">

                                <div className="detail-wrapper-header">
                                    <h4>Top Up Your SLWS Wallet</h4>
                                </div>
                                <div className="detail-wrapper-body">
                                    {topupItem.pay_pal_id !== "" &&
                                    <div className="panel-group pay-opt" id="accordion">

                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <span>Enter amount</span>
                                                    <img src="/assets/img/paypal.png" className="img-responsive"
                                                         alt=""/>
                                                </h4>
                                            </div>
                                            <div className="panel-body">
                                                <form className="c-form" id="placeOrderForm" method="post"
                                                      action={topupItem.pay_pal_url}>

                                                    <div className="row">

                                                        <div className="col-sm-12 form-holder" style={{marginTop: 15}}>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Amount in USD"
                                                                   required
                                                                   disabled={id !== '0'}
                                                                   value={topupItem.amount}/>

                                                            <input name="amount"
                                                                   type="hidden"
                                                                   value={topupItem.amount}/>
                                                        </div>

                                                        <div className="col-sm-12 form-holder">
                                                            <input type="hidden" name="business"
                                                                   value={topupItem.pay_pal_id}/>
                                                            <input type="hidden" name="cmd" value="_xclick"/>
                                                            <input type="hidden" name="item_name"
                                                                   value="SLWS Top Up"/>
                                                            <input type="hidden" name="item_number"
                                                                   value={topupItem.ref_no}/>
                                                            <input type="hidden" name="currency_code"
                                                                   value="USD"/>
                                                            <input type='hidden' name='notify_url'
                                                                   value={topupItem.ipn_url}/>

                                                            <input type='hidden'
                                                                   name='cancel_return'
                                                                   value={topupItem.cancel_url}/>
                                                            <input type='hidden'
                                                                   name='return'
                                                                   value={topupItem.succcess_url}/>
                                                        </div>
                                                        <div className="col-sm-12 form-holder" style={{marginTop: 10}}>
                                                            <button type="submit"
                                                                    className="btn btn-m btn-success full-width"
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        marginTop: 10
                                                                    }}>PROCEED TO
                                                                PAYMENT
                                                            </button>
                                                        </div>

                                                    </div>

                                                </form>
                                            </div>
                                        </div>


                                    </div>
                                    }

                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </section>

        </AuthProvider>

    )
};

export default TopUp;

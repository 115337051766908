import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import userPhoto from '../../Assets/Images/a3.png';


const Profile = props => {

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');

    const [stats, setStats] = useState({
        ongoing: 0,
        completed: 0,
        cancelled: 0
    });

    useEffect(() => {
        document.title = globalState.currentUser.name + " | " + Strings.APP_DESCRIPTION;

        setName(globalState.currentUser.name)
        setEmail(globalState.currentUser.email)

        loadStats()

    }, [globalState.currentUser]);

    const updateProfile = (e) => {
        e.preventDefault();

        if (!(!!name)) {
            Notify("Name is required", ERROR)
            return
        }

        if (!(!!email)) {
            Notify("Email is required", ERROR)
            return
        }

        let data = new FormData();
        data.append("name", name)
        data.append("email", email)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PROFILE, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, false, () => {
                        Notify("Profile updated successfully", SUCCESS)
                    });
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }


    const updatePassword = (e) => {
        e.preventDefault();

        if (!(!!currentPass)) {
            Notify("Current password is required", ERROR)
            return
        }

        if (newPass !== confirmNewPass) {
            Notify("Passwords do not match", ERROR)
            return
        }

        if (!(!!newPass)) {
            Notify("New password is required", ERROR)
            return
        }

        let data = new FormData();
        data.append("current", currentPass)
        data.append("new", newPass)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, false, () => {
                        Notify("Password updated successfully", SUCCESS)
                    });
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const updatePhoto = (file) => {

        let data = new FormData();
        data.append("file", file)

        setIsLoading(true);

        postData(Endpoints.UPDATE_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    handleUserAccess(res, globalActions, false, () => {
                        Notify("Photo updated successfully", SUCCESS)
                    });
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    function loadStats() {
        let data = new FormData();
        postData(Endpoints.GET_STATS, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setStats(res.data)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    return (
        <AuthProvider>
            <section className="create-company">
                <div className="container">

                    <div className="box">
                        <div className="box-body">

                            <div className="row full-width" style={{width: '100%'}}>


                                <div className="text-center col-md-3 col-sm-12">
                                    <img
                                        src={globalState.currentUser.photo !== "" ? Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo : userPhoto}
                                        className="img-responsive img-circle"
                                        alt=""
                                        style={{
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            display: 'block',
                                            width: 100,
                                            height: 100,
                                            alignSelf: 'center'
                                        }}
                                    />
                                    <br/>
                                    <strong style={{fontSize: 20}}>{globalState.currentUser.name}</strong><br/>
                                    <span>{globalState.currentUser.email}</span>
                                </div>

                                <div className="text-center col-md-3 col-sm-12">
                                    <strong style={{fontSize: 24}}>{stats.ongoing}</strong>
                                    <br/>
                                    <span style={{fontSize: 18}}>Current Orders</span><br/>
                                </div>

                                <div className="text-center col-md-3 col-sm-12">
                                    <strong style={{fontSize: 24}}>{stats.completed}</strong>
                                    <br/>
                                    <span style={{fontSize: 18}}>Completed Orders</span><br/>
                                </div>

                                <div className="text-center col-md-3 col-sm-12">
                                    <strong style={{fontSize: 24}}>{stats.cancelled}</strong>
                                    <br/>
                                    <span style={{fontSize: 18}}>Cancelled Orders</span><br/>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="tab style-1" role="tabpanel">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active">
                                <a href="#profile" aria-controls="home" role="tab" data-toggle="tab">Profile
                                    Settings</a>
                            </li>
                            <li role="presentation">
                                <a href="#photo" aria-controls="home" role="tab" data-toggle="tab">Display Photo</a>
                            </li>
                            <li role="presentation">
                                <a href="#password" aria-controls="profile" role="tab" data-toggle="tab">Password
                                    Settings</a>
                            </li>
                        </ul>
                        <div className="tab-content tabs">

                            <div role="tabpanel" className="tab-pane fade in active" id="profile">
                                <div className="row">
                                    <div className="col-sm-4">

                                        <div className="form-group">
                                            <label>Display Name</label>
                                            <input name="full_name" type="text" className="form-control"
                                                   placeholder="Display Name"
                                                   required
                                                   onChange={e => setName(e.target.value)}
                                                   value={name}/>
                                        </div>

                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input name="email" type="text" className="form-control"
                                                   placeholder="Email Address"
                                                   required
                                                   onChange={e => setEmail(e.target.value)}
                                                   value={email}/>
                                        </div>


                                        <div className="form-group text-center">
                                            {isLoading ?
                                                <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/> :
                                                <button type="submit" className="btn btn-success full-width btn-m"
                                                        onClick={updateProfile}>
                                                    <strong>Update Profile</strong>
                                                </button>}
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div role="tabpanel" className="tab-pane fade in" id="photo">
                                <div className="row">
                                    <div className="col-sm-4 text-center" style={{maxWidth: 230}}>
                                        <img
                                            src={globalState.currentUser.photo !== "" ? Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo : userPhoto}
                                            className="img-responsive img-circle" alt=""
                                            style={{margin: 0, display: 'block', width: 200, height: 200}}
                                        />
                                        <br/>

                                        {isLoading ?
                                            <ProgressView size={23}
                                                          style={{color: Colors.black, marginTop: 5}}/> :
                                            <span className="btn btn-sm rounded dark fileinput-button">
                                  <i className="fa fa-edit"></i>
                                     <span> Edit Photo</span>
                                        <input id="coverUpload" type="file" name="file"
                                               onChange={e => updatePhoto(e.target.files[0])}/>
                             </span>}

                                    </div>
                                </div>
                            </div>

                            <div role="tabpanel" className="tab-pane fade" id="password">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <form id="updatePasswordForm">

                                            <div className="form-group">
                                                <label>Current Password</label>
                                                <input name="password" type="password" className="form-control"
                                                       placeholder="Current Password"
                                                       required
                                                       onChange={e => setCurrentPass(e.target.value)}
                                                       value={currentPass}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input name="new_password" type="password" className="form-control"
                                                       placeholder="New Password"
                                                       required id="newPassword"
                                                       onChange={e => setNewPass(e.target.value)}
                                                       value={newPass}/>
                                            </div>

                                            <div className="form-group">
                                                <label>Confirm New Password</label>
                                                <input name="confirm_password" type="password" className="form-control"
                                                       placeholder="Confirm Password"
                                                       required id="confirmPassword"
                                                       onChange={e => setConfirmNewPass(e.target.value)}
                                                       value={confirmNewPass}/>
                                            </div>

                                            <div className="form-group text-center">
                                                {isLoading ?
                                                    <ProgressView size={23}
                                                                  style={{color: Colors.black, marginTop: 5}}/> :
                                                    <button type="submit" className="btn btn-success full-width btn-m"
                                                            onClick={updatePassword}>
                                                        <strong>Update Password</strong>
                                                    </button>}
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                </div>
            </section>
        </AuthProvider>

    )
};

export default Profile;

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import TransactionListSection from "./TransactionListSection";

const ipnUrl = ""
const cancelUrl = ""
const successUrl = ""
const paypalUrl = ""


const TransactionList = props => {

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        document.title = "Transactions | " + Strings.APP_DESCRIPTION;

    }, []);


    return (
        <AuthProvider>
            <section className="create-company">
                <div className="container">

                    <div className="detail-wrapper">

                        <div className="detail-wrapper-header">
                            <h4>My Transactions</h4>
                        </div>
                        <div className="detail-wrapper-body">

                                                                    <TransactionListSection status={"ongoing"}/>


                        </div>
                    </div>


                </div>
            </section>
        </AuthProvider>

    )
};

export default TransactionList;

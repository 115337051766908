import React from "react";
import {useGlobal} from "../../Store";
import {useHistory} from "react-router-dom";

const Footer = props => {
    const history = useHistory();
    const [globalState, globalActions] = useGlobal();

    return (
        <footer className="footer">
            <div className="container">

                <div className="row">
                    <div className="col-md-4 col-sm-4 text-center">
                        <h4>Subjects</h4>
                        <ul>
                            {globalState.subjects.map((i, k) => <li><span style={{cursor:"pointer"}} onClick={e => {
                                e.preventDefault()
                                history.push("/order", {subject: i})
                            }}>{i.title}</span></li>)}
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-4 text-center">
                        <h4>Papers</h4>
                        <ul>
                            {globalState.papers.map((i, k) => <li><span style={{cursor:"pointer"}} onClick={e => {
                                history.push("/order", {paper: i})
                            }}>{i.title}</span></li>)}
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-4 text-center">
                        <h4>Contact Info</h4>
                        <ul>
                            <li><a href="#">Nairobi, Kenya</a></li>
                            <li><a href="#">silverlinewritingservice@gmail.com</a></li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="copyright text-center">
                            <p>&copy; Copyright 2020 SLWS</p>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )

};

export default Footer;

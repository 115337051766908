import useGlobalHook from "use-global-hook";
import React from "react";

const initialState = {
    currentUser: {},
    userLoggedIn: false,
    userCheckComplete: false,
    siteData: {
        header_data: [],
        footer_data: []
    },
    subjects: [],
    papers: [],
    userIsIn: false,
    notCount: 0
};

export const actions = {
    setProfileMenu: (store, items) => {
        store.setState({profileMenu: items});
    },
    setSiteData: (store, data) => {
        store.setState({siteData: data});
    },
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user});
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn});
    },
    setUserIsIn: (store, loggedIn) => {
        store.setState({userIsIn: loggedIn});
    },
    setUserCheckComplete: (store, complete) => {
        store.setState({userCheckComplete: complete});
    },
    setSubjects: (store, cols) => {
        store.setState({subjects: cols});
    },
    setPapers: (store, cats) => {
        store.setState({papers: cats});
    },
    setNotCount: (store,n) => {
        store.setState({notCount: n});
    },
};

export const useGlobal = useGlobalHook(React, initialState, actions);

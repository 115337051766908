import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {Link, useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";

const OrderListSection = props => {

    const [orderList, setOrderList] = useState([])

    useEffect(() => {
        loadOrders(props.status)

        setInterval(() => {
            loadOrders(props.status)
        }, 1000 * 60)
    }, []);

    function loadOrders(status) {
        let data = new FormData();
        data.append("status", status)
        postData(Endpoints.ORDER_LIST, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setOrderList(res.data || [])
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const getLabel = e => {
        switch (e) {
            case "APPROVED", "IN PROGRESS", "REVISION":
                return "notice-info"
            case "DELIVERED":
                return "notice-success"
            case "CANCELLED":
                return "notice-danger"
        }
    }

    return (
        <div className="table-responsive">
            <table className="table table-lg table-hover">
                <thead>
                <tr>
                    <th>OrderID</th>
                    <th>Topic</th>
                    <th>Price</th>
                    <th>Deadline</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>

                <tbody>
                {orderList.map((i, k) => {
                    return <tr>
                        <td><Link to={"/order-details/" + i.string_id}
                                  style={{fontWeight: 500, color: Colors.green_700}}>Order
                            #{i.ID}</Link></td>
                        <td>{i.topic}</td>
                        <td>${i.formatted_price}</td>
                        <td>{i.remaining_hours}</td>
                        <td>
                            <div className={"notice " + getLabel(i.delivery_status)}>
                                <strong>{i.delivery_status}</strong>
                            </div>
                        </td>
                        <td><Link to={"/order-details/" + i.string_id} className="btn btn-sm btn-success">View
                            Order</Link>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
};

export default OrderListSection;

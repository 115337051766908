import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {Link, useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";

const MessageListSection = props => {

    const [messageList, setMessageList] = useState([])

    useEffect(() => {
        loadOrders(props.status)

        setInterval(() => {
            loadOrders(props.status)
        }, 1000 * 60)
    }, []);

    function loadOrders(status) {
        let data = new FormData();
        data.append("read_status", status)
        postData(Endpoints.LIST_USER_MESSAGES, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setMessageList(res.data || [])
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    return (
        <div className="table-responsive">
            <table className="table table-lg table-hover">
                <thead>
                <tr>
                    <th>From</th>
                    <th>Message</th>
                    <th>Order</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {messageList.map((i, k) => {
                    return <tr>
                        <td>
                            <i className="fa fa-comment"
                               style={{color: i.read_status === 0 ? Colors.red_700 : Colors.grey_600}}></i>
                            {i.type === "system" ? " SLWS" : " System"}
                        </td>
                        <td>{i.not_text}</td>
                        <td>
                            {i.order_id !== 0 ? <Link to={"/order-details/" + i.order_string}
                                                      style={{fontWeight: 500, color: Colors.green_700}}>Order
                                #{i.order_id}</Link> : "-"}
                        </td>
                        <td>{i.ago}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
};

export default MessageListSection;

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {Link, useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import OrderListSection from "./OrderListSection";
import Popup from 'react-popup';
import logo from '../../Assets/Images/logo.jpg';

const OrderDetail = props => {
    const {id} = props.match.params

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isRating, setIsRating] = useState(false);

    const [message, setMessage] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [noOfFiles, setNoOfFiles] = useState(0);
    const [messageList, setMessageList] = useState([]);
    const [rating, setRating] = useState(5);

    const [order, setOrder] = useState(false);

    const cancelOrder = () => {
        Popup.create({
            title: 'Cancel order',
            content: "Please confirm cancellation of this order",
            buttons: {
                left: [{
                    text: 'Cancel',
                    className: 'danger',
                    action: function () {
                        Popup.close();
                        initiateCancel()
                    }
                }],
                right: [{
                    text: 'Cancel',
                    className: '',
                    action: function () {
                        Popup.close();
                    }
                }]
            }
        });
    }

    const submitRating = () => {
        if (!(!!rating)) {
            Notify("Please select a rating", ERROR)
            return
        }

        setIsRating(true);

        let data = new FormData();
        data.append("order_id", id)
        data.append("ratings", rating.value)

        postData(Endpoints.RATE_ORDER, data)
            .then(res => res.json())
            .then(res => {

                setIsRating(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsRating(false);
                console.log(error)
            });

    }

    const initiateCancel = () => {
        setIsCancelling(true);

        let data = new FormData();
        data.append("order_id", id)

        postData(Endpoints.CANCEL_ORDER, data)
            .then(res => res.json())
            .then(res => {

                setIsCancelling(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    findOrder()
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsCancelling(false);
                console.log(error)
            });

    }

    useEffect(() => {
        document.title = "Orders | " + Strings.APP_DESCRIPTION;
        findOrder()
    }, []);


    const findOrder = () => {
        setIsLoading(true);

        let data = new FormData();
        data.append("order_id", id)

        postData(Endpoints.FIND_ORDER, data)
            .then(res => res.json())
            .then(res => {

                setIsLoading(false);
                if (res.success === 1) {
                    document.title = "Order # " + res.data.ID + " | " + Strings.APP_DESCRIPTION;
                    res.data.uploaded_files = res.data.uploaded_files || []
                    setOrder(res.data)
                    findOrderMessage(res.data.ID)

                    setRating(res.data.ratings)
                    setInterval(() => {
                        findOrderMessage(res.data.ID)
                    }, 3000)
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    const findOrderMessage = (id = order.ID) => {

        let data = new FormData();
        data.append("order_id", id)

        postData(Endpoints.LIST_MESSAGES, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setMessageList(res.data.reverse() || [])
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }

    const updateFiles = files => {
        console.log(files)
        let uploaded = uploadedFiles
        for (let i = 0; i < files.length; i++) {
            uploaded.push({file: files[i], name: files[i].name})
        }
        setUploadedFiles(uploaded)
        setNoOfFiles(uploaded.length)
    }

    const removeFile = index => {
        let uploaded = []
        for (let i = 0; i < uploadedFiles.length; i++) {
            if (i !== index) {
                uploaded.push(uploadedFiles[i])
            }
        }
        setUploadedFiles(uploaded)
        setNoOfFiles(uploaded.length)
    }

    const sendMessage = () => {
        if (!(!!message)) {
            Notify("Type a message", ERROR)
            return
        }

        setIsSendingMessage(true);

        let data = new FormData();
        data.append("order_id", id)
        data.append("message", message)

        for (let i = 0; i < uploadedFiles.length; i++) {
            data.append(`message_files[${i}]`, uploadedFiles[i].file)
        }

        postData(Endpoints.SEND_MESSAGE, data)
            .then(res => res.json())
            .then(res => {

                setIsSendingMessage(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                    setMessage('')
                    setUploadedFiles([])
                    findOrderMessage()
                } else {
                    Notify(res.message, ERROR)
                }
            })
            .catch((error) => {
                setIsSendingMessage(false);
                console.log(error)
            });

    }

    return (
        <AuthProvider>
            {order &&
            <section>
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 col-sm-12">

                            <div className="detail-wrapper">

                                <div className="detail-wrapper-header">
                                    <h4>Order #{order.ID}</h4>
                                    <h2>USD {order.formatted_price}</h2>
                                    {order.applied_discount > 0 &&
                                    <strong>*${order.applied_discount} discount applied</strong>
                                    }

                                    {order.promo_code !== "" &&
                                    <strong><br/>Promo Code : {order.promo_code}</strong>
                                    }
                                </div>
                                <div className="detail-wrapper-body">

                                    <p><strong>Topic</strong> : {order.topic}</p>

                                    <p><strong>Paper Type</strong> : {order.paper_id}</p>

                                    <p><strong>Subject</strong> : {order.subject_id}</p>

                                    <p><strong>Level of education</strong> : {order.education_level}</p>

                                    <p><strong>Number of Pages</strong> : {order.pages}</p>

                                    <p><strong>Type of Service</strong> : {order.service_type}</p>

                                    <p><strong>Number of Cited Resources</strong> : {order.cited_resources}
                                    </p>

                                    <p><strong>Format of Citation</strong> : {order.citation_format}</p>

                                    <p><strong>Deadline In</strong>
                                        : {order.remaining_hours}</p>

                                    <p><strong>Paper Instructions</strong> : {order.paper_instructions}</p>


                                    <div style={{marginTop: 20}}><strong>Uploaded files</strong> :
                                        <div className="side-list">
                                            <ul>

                                                {order.uploaded_files.map((i, k) =>
                                                    <li><i className="fa fa-files-o"></i> <a
                                                        target={"_"}
                                                        href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + i.file_name}
                                                        style={{
                                                            color: Colors.green_700,
                                                            textDecoration: 'underline'
                                                        }}>{i.file_name}</a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>

                                        {order.uploaded_files.length === 0 &&
                                        <p>No files uploaded</p>
                                        }

                                    </div>


                                </div>

                            </div>


                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="sidebar">
                                {order.status === "COMPLETED" &&
                                <div>
                                    <div className="notice notice-success">
                                        <strong><i
                                            className="fa fa-check"></i><i
                                            className="fa fa-check"></i> ORDER COMPLETED</strong>
                                    </div>


                                    <div className="col-sm-12" style={{marginTop: 15, marginBottom: 30}}>
                                        <div className="form-group">
                                            <label>Did you love our work? Please leave a rating</label>
                                            <SelectView
                                                placeholder={"Select a rating"}
                                                options={[{
                                                    label: "Excellent",
                                                    value: 5
                                                },
                                                    {
                                                        label: "Good",
                                                        value: 4
                                                    },
                                                    {
                                                        label: "Average",
                                                        value: 3
                                                    },
                                                    {
                                                        label: "Fair",
                                                        value: 2
                                                    },
                                                    {
                                                        label: "Poor",
                                                        value: 1
                                                    },
                                                ]}
                                                onChange={e => setRating(e)}
                                                value={rating}/>
                                        </div>
                                        {isRating ?
                                            <ProgressView size={23}
                                                          style={{color: Colors.black, marginTop: 5}}/> :
                                            <button type="submit" className="btn btn-success full-width btn-m"
                                                    onClick={submitRating}>
                                                <strong>Submit Rating</strong>
                                            </button>}
                                    </div>

                                </div>
                                }

                                {order.status === "DRAFT" &&
                                <Link to={"/topup/" + order.string_id + "/" + order.price}
                                      className="btn btn-m btn-success full-width mrg-bot-10"
                                      style={{fontWeight: 500}}><i
                                    className="fa fa-dollar"></i> MAKE PAYMENT USD {order.price}</Link>
                                }

                                {order.status === "CANCELLED" &&
                                <div className="notice notice-danger">
                                    <strong><i className="fa fa-close"></i> CANCELLED BY CLIENT
                                    </strong>
                                </div>
                                }

                                {order.status === "APPROVED" &&
                                <div className="notice notice-info">
                                    <strong>ORDER HAS BEEN APPROVED AND DELIVERY IS IN PROGRESS</strong>
                                </div>
                                }

                                {order.delivery_status === "REVISION" &&
                                <div className="notice notice-info">
                                    <strong>REVISION HAS BEEN REQUESTED</strong>
                                </div>
                                }

                                {order.performance_status === "LATE" &&
                                <div className="notice notice-danger">
                                    <strong>LATE ORDER</strong>
                                </div>
                                }

                                {order.delivery_status === "DELIVERED" &&
                                <div>
                                    <div className="notice notice-success">
                                        <strong><i className="fa fa-check"></i> ORDER HAS BEEN DELIVERED</strong>
                                    </div>
                                    <br/>
                                    <span>See order history for list of uploaded files.
                                You are free to request a revision. This order will be marked completed automatically after 3 days of no action.</span>
                                    <br/>
                                    <br/>

                                    <div className="full-width text-center">
                                        <span
                                            style={{fontWeight: 700, fontSize: 18}}>Revision request</span>
                                    </div>
                                    <div className="notice notice-info" style={{marginTop: 10}}>
                                        <strong><i className="fa fa-comment"></i> Reply to this order to request
                                            a revision</strong>
                                    </div>
                                </div>
                                }

                                <div>
                                    <div className="widget-boxed-header">
                                        <h5><i className="ti-comments padd-r-10"></i>Order History</h5>
                                    </div>
                                    <div className="widget-boxed-body">

                                        <div className="mesgs" style="width: 100%;background: white"
                                             style={{width: '100%', backgroundColor: Colors.white}}>

                                            <div className="msg_history" style={{maxHeight: '40vh'}}>
                                                {messageList.map((i, k) => {
                                                    if (i.user_from === "system") {
                                                        return (
                                                            <div className="incoming_msg" style={{marginTop: 5}}>
                                                                <div className="incoming_msg_img">

                                                                    {i.type === "delivery" ?
                                                                        <i className="fa fa-check-circle fa-3x"
                                                                           style={{color: Colors.green_700}}></i> :
                                                                        <img src={logo} alt="sunil"
                                                                             style={{width: 50, height: 50}}/>}
                                                                </div>
                                                                <div className="received_msg"
                                                                     style={{wordWrap: 'break-word'}}>
                                                                    <div className="received_withd_msg">

                                                                        <p style={{
                                                                            textTransform: 'none',
                                                                            fontSize: 13,
                                                                            backgroundColor: "#c4c4c4"
                                                                        }}> {i.not_text}
                                                                            {(i.files || []).length > 0 ? <br/> :
                                                                                <span></span>}

                                                                            {(i.files || []).map((a, b) =>
                                                                                <span style={{marginTop: 10}}>
                                                                                <br/>{b + 1}.<a target={"_"}
                                                                                                href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + a.file_name}
                                                                                                style={{
                                                                                                    color: Colors.black,
                                                                                                    textDecoration: 'underline'
                                                                                                }}>{a.file_name}</a></span>
                                                                            )}

                                                                            <br/>
                                                                        </p>
                                                                        <span
                                                                            className="time_date"> {i.ago}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="outgoing_msg">
                                                                <div className="sent_msg"
                                                                     style={{wordWrap: 'break-word'}}>

                                                                    <p style={{
                                                                        textTransform: 'none',
                                                                        fontSize: 13,
                                                                    }}>  {i.type === "delivery" ?
                                                                        <i className="fa fa-check-circle fa-3x"
                                                                           style={{color: Colors.green_700}}></i> :
                                                                        <img src={logo} alt="sunil"
                                                                             style={{width: 50, height: 50}}/>}
                                                                        {" " + i.not_text}
                                                                        {(i.files || []).length > 0 ? <br/> :
                                                                            <span></span>}

                                                                        {(i.files || []).map((a, b) =>
                                                                            <span style={{marginTop: 10}}>
                                                                                <br/>{b + 1}.<a target={"_"}
                                                                                                href={Endpoints.SERVER_HOST + Endpoints.API_URL + "download?file_name=" + a.file_name}
                                                                                                style={{
                                                                                                    color: Colors.white,
                                                                                                    textDecoration: 'underline'
                                                                                                }}>{a.file_name}</a></span>
                                                                        )}

                                                                        <br/>

                                                                    </p>

                                                                    <span className="time_date"> {i.ago}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>

                                        </div>


                                    </div>
                                </div>


                                {order.status !== "COMPLETED" && order.status !== "DRAFT" && order.status !== "CANCELLED" &&
                                <div className="box" style={{boxShadow: 'none'}}>
                                    <div className="box-body"
                                         style={{
                                             padding: '5%',
                                             borderTopWidth: 1,
                                             borderTopColor: Colors.grey_400,
                                             margin: 50
                                         }}
                                    >
                                        <form className="c-form" id="chartForm">
                                            <label>You may leave a message on this order</label>


                                            <textarea type="text"
                                                      className="form-control"
                                                      placeholder="Write a message"
                                                      style={{
                                                          maxWidth: '100%', maxHeight: '300px', minWidth: '100%'
                                                      }}
                                                      onChange={e => setMessage(e.target.value)}
                                                      value={message}/>


                                            {isSendingMessage &&
                                            <div style={{textAlign: 'center'}}>
                                                <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/>
                                            </div>
                                            }

                                            {!isSendingMessage &&
                                            <div className="form-group text-right">
                                                {uploadedFiles.map((i, k) => <p><i
                                                    className="fa fa-file"></i> {i.name} <i
                                                    style={{cursor: 'pointer', color: Colors.red_600}}
                                                    onClick={e => {
                                                        removeFile(k)
                                                    }}
                                                    className="fa fa-trash"></i></p>)}

                                                <span
                                                    className="btn btn-sm rounded dark fileinput-button text-dark"
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor: Colors.white,
                                                        color: Colors.black
                                                    }}>
                                                    <i className="fa fa-files-o"></i><span
                                                    style={{color: Colors.black}}> Attach Files</span>
                                                    <input id="fileUpload"
                                                           type="file"
                                                           name="file"
                                                           multiple
                                                           onChange={e => {
                                                               updateFiles(e.target.files)
                                                           }}/>
                                                    </span>
                                                <span className="btn btn-sm rounded dark-bg text-white" onClick={e => {
                                                    e.preventDefault()
                                                    sendMessage()
                                                }}
                                                      style={{color: Colors.white}} id="sendChatMessage"><i
                                                    className="fa fa-send"></i> Send Message</span>
                                            </div>
                                            }

                                        </form>
                                    </div>
                                </div>
                                }

                                {order.status !== "COMPLETED" &&
                                order.status !== "DRAFT" &&
                                order.status !== "CANCELLED" &&
                                order.status !== "DELIVERED" && !isCancelling &&
                                <div className="widget-boxed">
                                    <div className="widget-boxed-body">
                                        <a href="#" onClick={e => {
                                            e.preventDefault()
                                            cancelOrder()
                                        }}
                                           className="btn btn-m light-gray-btn full-width"><i
                                            className="ti-close"></i>Cancel
                                            Order</a>
                                    </div>
                                </div>
                                }

                                {isCancelling &&
                                <div style={{textAlign: 'center'}}>
                                    <ProgressView size={23} style={{color: Colors.black, marginTop: 5}}/>
                                </div>
                                }

                            </div>

                        </div>

                    </div>

                </div>
            </section>
            }

        </AuthProvider>

    )
};

export default OrderDetail;

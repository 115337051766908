import React, {useEffect, useState} from "react";
import Select from 'react-select';
import Colors from "../../Constants/Colors";

const SelectView = props => {

    const customStyles = {
        control: styles => ({
            ...styles,
            borderWidth: 1,
            borderColor: Colors.grey_300,
            boxShadow: 0,
            height:60,
            borderRadius:0.5,
            '&:hover': {
                borderWidth: 1.5,
                borderColor: Colors.grey_400
            },
            backgroundColor: Colors.white,
            color: Colors.black
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? Colors.amber_900 : Colors.black,
            backgroundColor: Colors.white,
            paddingLeft: 20,
            paddingRight:20
        }),
        singleValue: (provided, state) => {

        },
        menu: styles => ({
            ...styles,
            width: '90%'
        }),
    };

    return (
        <Select
            isSearchable={true}
            {...props}
            styles={customStyles}
            noOptionsMessage={({inputValue}) => "No options available"}/>
    )
};

export default SelectView;
import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";
import userPhoto from '../../Assets/Images/a3.png';


const ResetPassword = props => {

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');

    const {id} = props.match.params

    useEffect(() => {
        document.title = "Reset password | " + Strings.APP_DESCRIPTION;

    }, [globalState.currentUser]);

    const resetPassword = (e) => {
        e.preventDefault();

        if (!(!!newPass)) {
            Notify("New password is required", ERROR)
            return
        }

        if (newPass !== confirmNewPass) {
            Notify("Passwords do not match", ERROR)
            return
        }

        let data = new FormData();
        data.append("code", id)

        setIsLoading(true);

        postData(Endpoints.RESET_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false);
                if (res.success === 1) {
                    Notify(res.data, SUCCESS)
                } else {
                    if (Array.isArray(res.message)) {
                        Notify(res.message[0], ERROR);
                    } else {
                        Notify(res.message, ERROR);
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error)
            });

    }

    return (
        <section className="create-job">
            <div className="container">

                <div className="row">

                    <div className="col-sm-4">


                        <div className="detail-wrapper">

                            <div className="detail-wrapper-header">
                                <h5>Reset your password</h5>
                            </div>
                            <div className="detail-wrapper-body">

                                <div className="panel-group pay-opt" id="accordion">

                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <span>Enter your email address. We will send reset instructions to your email address.</span>
                                        </div>
                                        <div className="panel-body">
                                            <form className="c-form" id="resetPasswordForm">
                                                <div className="row">

                                                    <div className="form-group col-sm-12">
                                                        <label>New Password</label>
                                                        <input name="new_password" type="password"
                                                               className="form-control"
                                                               placeholder="New Password"
                                                               required id="newPassword"
                                                               onChange={e => setNewPass(e.target.value)}
                                                               value={newPass}/>
                                                    </div>

                                                    <div className="form-group col-sm-12">
                                                        <label>Confirm New Password</label>
                                                        <input name="confirm_password" type="password"
                                                               className="form-control"
                                                               placeholder="Confirm Password"
                                                               required id="confirmPassword"
                                                               onChange={e => setConfirmNewPass(e.target.value)}
                                                               value={confirmNewPass}/>
                                                    </div>

                                                    <div className="col-sm-12 form-holder" style={{marginTop: 15}}>
                                                        <button type="submit"
                                                                className="btn btn-m btn-success full-width"
                                                                style={{fontWeight: 700, marginTop: 10}}
                                                                onClick={resetPassword}>RESET PASSWORD
                                                        </button>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>


                                </div>


                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </section>

    )
};

export default ResetPassword;

import React, {useEffect, useState} from "react";
import {useGlobal} from "../../Store";
import {postData} from "../../Utils/Server";
import Endpoints from "../../Constants/Endpoints";
import {ERROR, Notify, SUCCESS} from "../../Utils/Notify";
import {handleUserAccess} from "../../Utils/Auth";
import Strings from "../../Constants/Strings";
import ProgressView from "../Views/ProgressView";
import Colors from "../../Constants/Colors";
import SelectView from "../Views/SelectView";
import {Link, useHistory} from "react-router-dom";
import AuthProvider from "../Views/AuthProvider";

const TransactionListSection = props => {

    const [transactionList, setTransactionList] = useState([])

    useEffect(() => {
        loadOrders(props.status)

        setInterval(() => {
            loadOrders(props.status)
        }, 1000 * 60)
    }, []);

    function loadOrders(status) {
        let data = new FormData();
        postData(Endpoints.TRANSACTION_LIST, data)
            .then(res => res.json())
            .then(res => {
                if (res.success === 1) {
                    setTransactionList(res.data || [])
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    return (
        <div className="table-responsive">
            <table className="table table-lg table-hover">
                <thead>
                <tr>
                    <th>Transaction Date</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Order</th>
                    <th>Amount</th>
                    <th>Status</th>
                </tr>
                </thead>

                <tbody>
                {transactionList.map((i, k) => {
                    return <tr>
                        <td>
                            <i className="ti-calendar"></i> {i.date_string}
                        </td>
                        <td>{i.type}</td>
                        <td>{i.transaction_text}</td>
                        <td>
                            {i.order_id !== "" ? <Link to={"/order-details/" + i.order_id}
                                                       style={{fontWeight: 500, color: Colors.green_700}}>Order
                                #{i.order_int}</Link> : "-"}
                        </td>
                        <td>$ {i.amount}</td>
                        <td>{i.status}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
};

export default TransactionListSection;
